/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"ROKLEN10let"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"4h9gdwxa7wm"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--24" content={"Děkujeme za potvrzení účasti na oslavě R10!"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":399}} content={"Vaši vstupenku Vám zašleme na e-mail, prosíme, uschovejte si ji, budete ji potřebovat při vstupu.\n<br><br>&nbsp;Dress code: Business casual\n\n "}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--cColor2 btn-box--design3 btn-box--filling2 btn-box--center fs--26" style={{"maxWidth":1000,"backgroundColor":"var(--black)"}} content={"<span style=\"color: rgb(135, 113, 77);\">Jak se dostat do FOOD LABu?</span>"} url={"https://maps.app.goo.gl/WLigm6Nod9bCKAFF9"} href={"https://maps.app.goo.gl/WLigm6Nod9bCKAFF9"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=350x_.png 350w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=660x_.png 660w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=860x_.png 860w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/39934/1346752a1cc54964bf81ca8a6aa1025e_s=2000x_.png 2000w"}>
              </Image>

              <Button className="btn-box btn-box--pbtn2 btn-box--center pl--40 pr--40" style={{"maxWidth":481}} content={"Přidat událost do kalendáře"} url={"<a target=\"_blank\" href=\"https://calendar.google.com/calendar/event?action=TEMPLATE&amp;tmeid=NTQ1OTNxbjJkOTA4MHJmNGdocTZ1bnU4YWUgZWVhMmY3ZWNjNTRkMjVhMGU5M2ZiNTM3ZGM1NjE4ZjdhZDcyOTZjNjQwYzgxZDJmNDVmYTc2N2I0OTFhMmE5OUBn&amp;tmsrc=eea2f7ecc54d25a0e93fb537dc5618f7ad7296c640c81d2f45fa767b491a2a99%40group.calendar.google.com\"><img border=\"0\" src=\"https://www.google.com/calendar/images/ext/gc_button1_cs.gif\"></a>"} href={"<a target=\"_blank\" href=\"https://calendar.google.com/calendar/event?action=TEMPLATE&amp;tmeid=NTQ1OTNxbjJkOTA4MHJmNGdocTZ1bnU4YWUgZWVhMmY3ZWNjNTRkMjVhMGU5M2ZiNTM3ZGM1NjE4ZjdhZDcyOTZjNjQwYzgxZDJmNDVmYTc2N2I0OTFhMmE5OUBn&amp;tmsrc=eea2f7ecc54d25a0e93fb537dc5618f7ad7296c640c81d2f45fa767b491a2a99%40group.calendar.google.com\"><img border=\"0\" src=\"https://www.google.com/calendar/images/ext/gc_button1_cs.gif\"></a>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"} style={{"backgroundColor":"rgba(0, 0, 0, 1)","paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Image style={{"maxWidth":100}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/39934/ab02196e8ffb49b5838ec1be45127715_s=350x_.PNG 350w"} alt={""} src={"https://cdn.swbpg.com/t/39934/ab02196e8ffb49b5838ec1be45127715_s=350x_.PNG"}>
              </Image>

              <Subtitle className="subtitle-box fs--12 lh--16" content={"<span style=\"color: var(--color-dominant)\">Václavské Nám. 838/9, Praha 1, 110 00<br>roklen10@roklen.cz&nbsp;<br>+420 236 071 600</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":449}} content={"<span style=\"color: var(--color-dominant)\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}